import React from 'react';
import Layout from '../components/layout';
import { getLayoutItem } from '../helpers/layoutHelper';
import { Link, graphql, useStaticQuery } from 'gatsby';
import Seo from '../components/seo';
import HtmlParser from '../helpers/htmlParser';
import '../utils/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GatsbyImage } from 'gatsby-plugin-image';
import moment from 'moment';

const { getName } = require('country-list');

const Homepage = ({ pageContext }) => {
    let alt = '';
    const { response } = pageContext;
    const layout_items = Object.values(response['0'].relationships).map(getLayoutItem);
    const news = useStaticQuery(graphql`
        query HomepageQuery {
            drupal {
                nodeQuery(
                    filter: {
                        conditions: { field: "type", value: "page_news", operator: EQUAL }
                        groups: {
                            conditions: { field: "type", value: "page_event", operator: EQUAL }
                            groups: { conditions: { field: "status", value: "1", operator: EQUAL } }
                            conjunction: AND
                        }
                        conjunction: OR
                    }
                    limit: 4
                    sort: { field: "created", direction: DESC }
                ) {
                    entities {
                        entityBundle
                        entityUrl {
                            path
                        }
                        ... on Drupal_NodePageEvent {
                            nid
                            uuid
                            title
                            fieldAddress {
                                countryCode
                                postalCode
                                givenName
                                locality
                            }
                            body {
                                processed
                            }
                            fieldEventDate {
                                endDate
                                startDate
                                endValue
                                value
                            }
                            fieldPressCategory {
                                entity {
                                    title
                                }
                            }
                            fieldOfficialEventWebsite {
                                url {
                                    path
                                }
                            }
                            fieldMedia {
                                notStyledImage {
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                }
                                entity {
                                    uuid
                                    url
                                }
                                url
                            }
                        }
                        ... on Drupal_NodePageNews {
                            nid
                            uuid
                            title
                            body {
                                processed
                                summaryProcessed
                            }
                            fieldAttachment {
                                entity {
                                    uuid
                                }
                            }
                            fieldDate {
                                date
                                value
                            }
                            fieldFile {
                                entity {
                                    uuid
                                }
                            }
                            fieldMedia {
                                entity {
                                    uuid
                                    url
                                }
                                notStyledImage {
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                }
                                url
                            }
                            fieldNewsType
                            fieldPressCategory {
                                entity {
                                    title
                                }
                            }
                            fieldUrl {
                                url {
                                    path
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    let pageElements = [];
    news.drupal.nodeQuery.entities.forEach((element, index) => {
        if (element != null) {
            if (element?.entityBundle === 'page_event') {
                let pageEventData = [];
                if (element?.fieldAddress?.locality.length > 0) {
                    pageEventData.push(element?.fieldAddress?.locality + ',');
                } else {
                    pageEventData.push(<></>);
                }

                pageElements.push(
                    <article className='news__boxes--box news__boxes--box' key={index}>
                        <div className='news__box--elements news__box--element'>
                            <div className='news__box--elements--image'>
                                {/*<Img
                                      alt={alt}
                                      fluid={element?.fieldMedia?.[0]?.notStyledImage?.childImageSharp?.fluid}
                               />*/}
                                <GatsbyImage
                                    alt={alt}
                                    image={
                                        element?.fieldMedia?.[0]?.notStyledImage?.childImageSharp
                                            ?.gatsbyImageData
                                    }
                                />
                            </div>
                            <div className='news__box--elements--text'>
                                <div className='news__box--elements--header'>
                                    <div className='news__box--elements--type box__type--calendar'>
                                        <FontAwesomeIcon icon={'calendar-alt'} />
                                    </div>
                                    <div className='news__box--elements--links'>
                                        <ul>
                                            <a href='https://www.facebook.com/activemediausa'>
                                                <li className='news__box--elements--links--facebook'>
                                                    <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                                                    <p className='hide-this-element'>Facebook</p>
                                                </li>
                                            </a>
                                            <a href='https://twitter.com/ActiveMedia_USA'>
                                                <li className='news__box--elements--links--twitter'>
                                                    <FontAwesomeIcon icon={['fab', 'twitter']} />
                                                    <p className='hide-this-element'>Twitter</p>
                                                </li>
                                            </a>
                                            <a href='https://www.linkedin.com/company/activemedia'>
                                                <li className='news__box--elements--links--linkedin'>
                                                    <FontAwesomeIcon
                                                        icon={['fab', 'linkedin-in']}
                                                    />
                                                    <p className='hide-this-element'>Linkedin</p>
                                                </li>
                                            </a>
                                        </ul>
                                    </div>
                                </div>
                                <div className='news__box--body'>
                                    <div className='news__box--body--title'>
                                        <h3>
                                            <Link to={element?.entityUrl?.path}>
                                                {element?.title}
                                            </Link>
                                        </h3>
                                    </div>
                                    <div className='news__box--body--info'>
                                        <p className='location'>
                                            <FontAwesomeIcon icon={'map-marker-alt'} />{' '}
                                            {pageEventData}{' '}
                                            {element?.fieldAddress?.countryCode
                                                ? getName(element?.fieldAddress?.countryCode)
                                                : ''}
                                        </p>
                                    </div>
                                    <div className='news__box--body--info'>
                                        <p className='date'>
                                            <FontAwesomeIcon icon={'calendar'} />{' '}
                                            {moment(element?.fieldEventDate?.startDate).format(
                                                'dddd, D MMMM, YYYY'
                                            )}{' '}
                                            {element.fieldEventDate.endDate ? 'to' : ''}{' '}
                                            {element.fieldEventDate.endDate
                                                ? moment(element.fieldEventDate.endDate).format(
                                                      'dddd, D MMMM, YYYY'
                                                  )
                                                : ''}
                                        </p>
                                    </div>
                                    <div className='news__box--body--category'>
                                        <Link to='#'>
                                            {element?.fieldPressCategory[0]?.entity?.title}
                                        </Link>
                                    </div>
                                    <div className='news__box--body--text'>
                                        <HtmlParser
                                            html={
                                                element?.body?.processed !== undefined
                                                    ? element?.body?.processed.substring(0, 450)
                                                    : ''
                                            }
                                        />
                                    </div>
                                    <div className='news__box--body--btn'>
                                        <Link to={element?.entityUrl?.path}>Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                );
            }
            if (element.entityBundle === 'page_news') {
                pageElements.push(
                    <article className='news__boxes--box news__boxes--box' key={index}>
                        <div className='news__box--elements news__box--element'>
                            <div className='news__box--elements--image'>
                                {/*<Img className="news__box--elements--image"
                                      alt={alt}
                                      fluid={element?.fieldMedia?.[0]?.notStyledImage?.childImageSharp?.fluid}
                                  />*/}
                                <GatsbyImage
                                    alt={alt}
                                    image={
                                        element?.fieldMedia?.[0]?.notStyledImage?.childImageSharp
                                            ?.gatsbyImageData
                                    }
                                />
                            </div>
                            <div className='news__box--elements--text'>
                                <div className='news__box--elements--header'>
                                    <div className='news__box--elements--type box__type--newspaper'>
                                        <FontAwesomeIcon icon={['far', 'newspaper']} />
                                    </div>
                                    <div className='news__box--elements--links'>
                                        <ul>
                                            <a href='https://www.facebook.com/activemediausa'>
                                                <li className='news__box--elements--links--facebook'>
                                                    <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                                                    <p className='hide-this-element'>Facebook</p>
                                                </li>
                                            </a>
                                            <a href='https://twitter.com/ActiveMedia_USA'>
                                                <li className='news__box--elements--links--twitter'>
                                                    <FontAwesomeIcon icon={['fab', 'twitter']} />
                                                    <p className='hide-this-element'>Twitter</p>
                                                </li>
                                            </a>
                                            <a href='https://www.linkedin.com/company/activemedia'>
                                                <li className='news__box--elements--links--linkedin'>
                                                    <FontAwesomeIcon
                                                        icon={['fab', 'linkedin-in']}
                                                    />
                                                    <p className='hide-this-element'>Linkedin</p>
                                                </li>
                                            </a>
                                        </ul>
                                    </div>
                                </div>
                                <div className='news__box--body'>
                                    <div className='news__box--body--title'>
                                        <h3>
                                            <Link to={element?.entityUrl?.path}>
                                                {element?.title}
                                            </Link>
                                        </h3>
                                    </div>
                                    <div className='news__box--body--info'>
                                        <p className='date'>
                                            <FontAwesomeIcon icon={'calendar'} />{' '}
                                            {moment(element.fieldDate.value).format('MM/DD/YYYY')}
                                        </p>
                                    </div>
                                    <div className='news__box--body--category'>
                                        <Link to='#'>
                                            {element?.fieldPressCategory[0]?.entity?.title}
                                        </Link>
                                    </div>
                                    <div className='news__box--body--text'>
                                        <HtmlParser
                                            html={
                                                element?.body?.processed !== undefined
                                                    ? element.body.processed.substring(0, 450)
                                                    : ''
                                            }
                                        />
                                    </div>
                                    <div className='news__box--body--btn'>
                                        <Link to={element?.entityUrl?.path}>Read More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                );
            }
        }
    });

    return (
        <Layout>
            <Seo title='Online Marketing Agency' />
            {/* <pre>{JSON.stringify(pageContext, {}, 4)}</pre> */}
            <div className='homepage'>
                <div className='main__container'>
                    {layout_items}
                    <section className='news__container'>
                        <div className='news__boxes'>{pageElements}</div>
                    </section>
                </div>
            </div>
        </Layout>
    );
};

export default Homepage;
